<template>
  <b-card-actions
      ref="first-card"
      class="list-card"
      @refresh="refreshStop('first-card')"
  >
    <b-row class="mt-50 mb-5">
      <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
        <b-col cols="12" class="d-flex justify-content-between">
          <h4 class="text-white d-inline-block card-title">{{ title }}</h4>
          <div>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="row-btn mr-50"
                :to="`/vardiyaDetayi/${vardiyaID}`"
            >
              <font-awesome-icon icon="database" class="mr-50"/>
              Vardiya Detayı
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="row-btn mr-50"
                @click="print()"
            >
              <font-awesome-icon icon="print" class="mr-50"/>
              Yazdır
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="row-btn"
                @click="$router.go(-1)"
            >
              <font-awesome-icon icon="chevron-left" class="mr-50"/>
              Geri Dön
            </b-button>
          </div>
        </b-col>
      </div>
    </b-row>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        class="mobile-row-btn mr-50"
        :to="`/vardiyaDetayi/${vardiyaID}`"
    >
      <font-awesome-icon icon="database" class="mr-50"/>
      Vardiya Detayı
    </b-button>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="info"
        class="mobile-row-btn mr-50"
        @click="print()"
    >
      <font-awesome-icon icon="print" class="mr-50"/>
      Yazdır
    </b-button>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        class="mobile-row-btn"
        @click="$router.go(-1)"
    >
      <font-awesome-icon icon="chevron-left" class="mr-50"/>
      Geri Dön
    </b-button>
    <div ref="ref-detayli-tablo" class="print-area">
      <hr>
      <h4 class="font-weight-bolder">Gelir Listesi</h4>
      <b-row>
        <b-col lg="8" md="12" sm="12" cols="12">
          <b-table
              striped
              hover
              responsive
              class="position-relative gelir-kayitlari-table mt-1"
              :items="gelirList"
              :fields="gelirFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
          >
            <template #cell(user)="data">
              <p class="font-weight-bolder d-inline-block ml-50 m-0">{{ data.item.userName }}</p>
            </template>
            <template #cell(departmanAdi)="data">
              <p class="d-inline-block font-weight-bolder ml-50 m-0"
                 :class="{'text-primary' : data.item.adisyonID !== '0'}"
                 @click="data.item.adisyonID !=='0' ? goAdisyonDetay(data.item):null"
              >
                {{ data.item.departmanAdi === 'Adisyon' ? `(${data.item.masaAdi})` : data.item.departmanAdi }}</p>
            </template>
            <template #cell(odemeTuru)="data">
              {{ getOdemeTuru(data.item.odemeTuru) }}
            </template>
            <template #cell(gelir)="data">
              <strong v-if="data.item.gelir">
                {{ data.item.gelir }} TL
              </strong>
            </template>
            <template #cell(fisNo)="data">
              <p v-if="data.item.fisNo" class="m-0">
                # {{ data.item.fisNo }}
              </p>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
        </b-col>
        <b-col lg="4" md="12" sm="12" cols="12">
          <strong>Departman Gelir Kayıtları</strong>
          <b-list-group flush>
            <b-list-group-item v-for="(item,i) in departmanList" :key="i">{{
                `${item.departmanAdi} ${item.toplamTutar}`
              }} TL
            </b-list-group-item>
          </b-list-group>
          <div class="finans-divider"/>
          <b-list-group flush>
            <b-list-group-item v-for="(item ,i) in gelirFinans" :key="i" class="font-weight-bolder">
              {{ getOdemeTuru(item.odemeTuru) }} : {{ item.toplamTutar }} TL
            </b-list-group-item>
            <b-list-group-item class="font-weight-bolder">
              <h3 class="m-0">Toplam Gelir : {{ finans.toplamGelir || 0 }} TL</h3>
            </b-list-group-item>
          </b-list-group>
          <small class="float-right">*Toplam tutara yansımaz</small>
        </b-col>
      </b-row>
      <hr>
      <h4 class="font-weight-bolder">Vardiya Tahsilat Listesi</h4>
      <b-row>
        <b-col lg="8" md="12" sm="12" cols="12">
          <b-table
              striped
              hover
              responsive
              class="position-relative gelir-kayitlari-table mt-1"
              :items="tahsilatList"
              :fields="tahsilatFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
          >
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block ml-50 m-0 font-weight-bolder">{{ data.item.userName }}</p>
            </template>
            <template #cell(unvan)="data">
              <p class="font-weight-bolder m-0">{{ data.item.unvan }}</p>
            </template>
            <template #cell(isim)="data">
              <p class="m-0">{{ data.item.isim }}</p>
            </template>
            <template #cell(tutar)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.tutar }} TL</p>
            </template>
            <template #cell(tahsilatTuru)="data">
              <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ getOdemeTuru(data.item.tahsilatTuru) }}</p>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
        </b-col>
        <b-col lg="4" md="12" sm="12" cols="12">
          <b-list-group flush>
            <b-list-group-item v-for="(item,i) in tahsilatGelir" :key="i" class="font-weight-bolder">
              {{ getOdemeTuru(item.tahsilatTuru) }} :
              {{ item.toplamTutar }} TL
            </b-list-group-item>
            <b-list-group-item class="font-weight-bolder">
              <h3 class="m-0">Toplam Tahsilat : {{ finans.toplamTahsilat || 0 }} TL</h3>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <hr>
      <h4 class="font-weight-bolder">Gider Listesi</h4>
      <b-row>
        <b-col lg="8" md="12" sm="12" cols="12">
          <b-table
              striped
              hover
              responsive
              class="position-relative gelir-kayitlari-table mt-1"
              :items="giderList"
              :fields="giderFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
          >
            <template #cell(userName)="data">
              <font-awesome-icon icon="user"/>
              <p class="d-inline-block ml-50 m-0 font-weight-bolder">{{ data.item.userName }}</p>
            </template>
            <template #cell(tutar)="data">
              <p class="font-weight-bolder m-0">{{ data.item.tutar }} TL</p>
            </template>
            <template #cell(fisNo)="data">
              <p class="m-0">{{ data.item.fisNo }}</p>
            </template>
            <template #cell(aciklama)="data">
              <p class="m-0">{{ data.item.aciklama }}</p>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
            </template>
          </b-table>
        </b-col>
        <b-col lg="4" md="12" sm="12" cols="12">
          <b-list-group flush>
            <b-list-group-item class="font-weight-bolder">
              <h3 class="m-0">Toplam Gider : {{ finans.toplamGider || 0 }} TL</h3>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <div class="total-divider"/>
      <b-row class="mt-1">
        <b-col cols="12">
          <h4 class="m-0 ml-1">Vardiya Kasa Durumu</h4> <small class="ml-1">* Tahsilat ve Giderler Sonrası Kasa</small>
          <b-list-group flush>
            <b-list-group-item>Toplam Nakit : {{ finans.toplamNakit }} TL</b-list-group-item>
            <b-list-group-item>Toplam Kredi Kartı : {{ finans.toplamKrediKarti }} TL</b-list-group-item>
            <b-list-group-item>Toplam Havale: {{ finans.toplamHavale }} TL</b-list-group-item>
            <b-list-group-item><h3>Genel Toplam : {{ finans.genelToplam }} TL</h3></b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </b-card-actions>
</template>

<script>
import {
  BTable,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'
import router from '@/router'

export default {
  components: {
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      vardiyaID: this.$route.params.id,
      gelirFields: [
        {
          key: 'user',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'departmanAdi',
          label: 'Departman',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'odemeTuru',
          label: 'Ödeme Türü',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'gelir',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'fisNo',
          label: 'Fiş No',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      giderFields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'fisNo',
          label: 'Fiş No',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklaması',
          sortable: true,
          thStyle: {
            width: '30%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },
        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '25%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },

        },
      ],
      tahsilatFields: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'unvan',
          label: 'Unvan',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'isim',
          label: 'isim',
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tahsilatTuru',
          label: 'Tahsilat Türü',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '25%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center', 'text-nowrap'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    title() {
      return 'Detaylı Tablo'
    },
    gelirList: () => store.getters.GET_DETAYLI_TABLO.gelirList || [],
    giderList: () => store.getters.GET_DETAYLI_TABLO.giderList || [],
    departmanList: () => store.getters.GET_DETAYLI_TABLO.departmanGelir || [],
    tahsilatList: () => store.getters.GET_DETAYLI_TABLO.tahsilatList || [],
    tahsilatGelir: () => store.getters.GET_DETAYLI_TABLO.tahsilatGelir || [],
    gelirFinans: () => store.getters.GET_DETAYLI_TABLO.gelirBilgileri || [],
    finans: () => ({
      genelToplam: store.getters.GET_DETAYLI_TABLO?.genelToplam,
      toplamNakit: store.getters.GET_DETAYLI_TABLO?.toplamNakit,
      toplamKrediKarti: store.getters.GET_DETAYLI_TABLO?.toplamKrediKarti,
      toplamHavale: store.getters.GET_DETAYLI_TABLO?.toplamHavale,
      toplamTahsilat: store.getters.GET_DETAYLI_TABLO?.toplamTahsilat,
      toplamGelir: store.getters.GET_DETAYLI_TABLO?.toplamGelir,
      toplamGider: store.getters.GET_DETAYLI_TABLO?.toplamGider,
    }),
    vardiya() {
      return {
        vardiyaDetay: store.getters.GET_VARDIYA_DETAY,
        finans: {
          ciro: store.getters.GET_VARDIYA_DETAY.finans?.ciro || 0,
          nakit: store.getters.GET_VARDIYA_DETAY.finans?.nakit || 0,
          krediKarti: store.getters.GET_VARDIYA_DETAY.finans?.krediKarti || 0,
          cariKredi: store.getters.GET_VARDIYA_DETAY.finans?.cariKredi || 0,
          havale: store.getters.GET_VARDIYA_DETAY.finans?.havale || 0,
          krediTahsilat: store.getters.GET_VARDIYA_DETAY.finans?.krediTahsilat || 0,
          gider: store.getters.GET_VARDIYA_DETAY.finans?.gider || 0,
          netKasa: store.getters.GET_VARDIYA_DETAY.finans?.netKasa || 0,
        },
      }
    },
  },
  created() {
    this.detayData()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_DETAYLI_TABLO',
      action: 0,
    })
  },
  methods: {
    goAdisyonDetay: event => router.push(`/order/${event.masaID}/${event.adisyonID}`),
    print() {
      store.dispatch('tablePrint', this.$refs['ref-detayli-tablo'].innerHTML)
    },
    getOdemeTuru: tur => (tur === '1' ? 'Nakit' : tur === '2' ? 'Havale' : tur === '3' ? 'Kredi Kartı' : tur === '4' ? 'Cari Kredisi' : 'Nakit'),
    detayData() {
      const fd = this.postSchema()
      fd.set('methodName', 'gelirListesi')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_DETAYLI_TABLO',
                action: 1,
                list: response.gelirData,
              })
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'vardiya')
      if (this.vardiyaID !== '0' && this.vardiyaID !== 0) {
        fd.append('vardiyaID', this.vardiyaID)
      }
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>

.gelir-kayitlari-table .table td {
  padding: 0.22rem 2rem !important;
}

.gider-kayitlari-table .table td {
  padding: 0.22rem 2rem !important;
}

.print-area .finans-divider {
  border: 1px solid #B9B4C7;
}

.print-area .total-divider {
  border: 1px solid #B9B4C7;
}

</style>
